import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';
import { Payload, useContactUsForm } from '../hooks/useContactUsForm';
import { useContactUsMutation } from '../mutations/useContactUsMutation';
import FormInput from './form/formInput';
import FormTextArea from './form/formTextArea';
import RouteLink from '../../app/components/RouteLink';
import FileInput from './form/fileInput';
import { GA4Event, GoogleTagManager } from '../../../lib/gtm';
import { useRouting } from '../../../routing/utils/useRouting';

interface ComponentProps {
  bannerText?: string;
  jobsContact?: boolean | null;
}

const ContactForm: React.FC<ComponentProps> = ({ bannerText, jobsContact = false }) => {
  const [vop, setVop] = useState<boolean>(false);
  const [vopError, setVopError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { routes } = useRouting();

  const { register, handleSubmit, errors, reset, getValues, setValue } = useContactUsForm(jobsContact);

  const { mutate, isLoading } = useContactUsMutation({
    onSuccess: () => {
      reset();
      setVop(false);
      setSuccess(true);
      setVopError(null);
    },
    onError: () => {
      setError(true);
    },
  });

  const submit = (payload: Payload) => {
    setSuccess(false);
    setError(false);

    if (!vop) {
      setVopError('Musíte souhlasit s podmínkami ');
      return;
    }

    mutate(payload);

    GoogleTagManager.updateDataLayer(GA4Event.CONTACT_FORM_SENT, {
      firstName: payload.firstname,
      lastName: payload.lastname,
      email: payload.email,
      company: payload.company,
      message: payload.note,
    });
  };

  return (
    <SC.Contact id={'contact-form'} jobsContact={jobsContact}>
      <div id={'contact'} />
      <div className={'container'}>
        <div className={'block-headline'}>
          <div>Kontaktujte nás</div>
        </div>
        {bannerText && <div className={'contact-banner'}>{bannerText}</div>}
        {!bannerText && (
          <div className={'contact-banner'}>
            Máte zájem o nezávaznou konzultaci nebo se chcete na něco zeptat? Zavolejte, napište nebo za námi přijeďte.
          </div>
        )}
        <div className={'contact-form'}>
          <form className={'form'} onSubmit={handleSubmit(submit)}>
            <div className={'form-box'}>
              <div className={'side usp'}>
                {!jobsContact && (
                  <div className={'information-wrapper'}>
                    <div className={'information-box'}>
                      <div className={'plus'}>
                        <div className={'line'} />
                        <div className={'line line-vertical'} />
                      </div>
                      <div className={'information-box__text'}>Na trhu fungujeme už od roku 2007.</div>
                    </div>
                    <div className={'information-box'}>
                      <div className={'plus'}>
                        <div className={'line'} />
                        <div className={'line line-vertical'} />
                      </div>
                      <div className={'information-box__text'}>Jednáme férově, a co slíbíme, to dodržíme.</div>
                    </div>
                    <div className={'information-box'}>
                      <div className={'plus'}>
                        <div className={'line'} />
                        <div className={'line line-vertical'} />
                      </div>
                      <div className={'information-box__text'}>Ozveme se vám během pár hodin.</div>
                    </div>
                    <div className={'information-box'}>
                      <div className={'plus'}>
                        <div className={'line'} />
                        <div className={'line line-vertical'} />
                      </div>
                      <div className={'information-box__text'}>Vstupní analýza v rozsahu až 5 MD zdarma.</div>
                    </div>
                  </div>
                )}
                {!jobsContact && (
                  <div className={'contact-banner contact-banner-bottom'}>
                    <div className={'title'}> Nechcete čekat na odpověď?</div>
                    Zavolejte nám na číslo &nbsp;
                    <a className={'link'} href={'tel:+420736102601'}>
                      +420 736 102 601
                    </a>
                    &nbsp; nebo se za námi zastavte – najdete nás v <a className={'link'}>Praze</a> a v &nbsp;
                    <a className={'link'}> Brně</a>.
                  </div>
                )}
              </div>
              <div className={'side'}>
                {success && (
                  <div className={'alert alert-success'}>
                    Poptávka byla úspěšně odeslána. Budeme Vás co nejdříve kontaktovat.
                  </div>
                )}
                {error && (
                  <div className={'alert alert-danger'}>Nepodařilo se odeslat poptávku. Zkuste to prosím později</div>
                )}
                <div className={'row'}>
                  <FormInput
                    label={'Jméno'}
                    placeholder={'Jméno*'}
                    register={register}
                    identificator={'firstname'}
                    error={errors.firstname}
                    required={true}
                    type={'text'}
                    getValues={getValues}
                  />
                  <FormInput
                    label={'Přijmení'}
                    placeholder={'Přijmení*'}
                    register={register}
                    identificator={'lastname'}
                    error={errors.lastname}
                    required={true}
                    type={'text'}
                    getValues={getValues}
                  />
                </div>
                <div className={'row'}>
                  <FormInput
                    label={'e-mail'}
                    placeholder={'e-mail*'}
                    register={register}
                    identificator={'email'}
                    error={errors.email}
                    required={true}
                    type={'email'}
                    getValues={getValues}
                  />
                  <FormInput
                    label={'Společnost'}
                    placeholder={'Společnost'}
                    register={register}
                    identificator={'company'}
                    error={errors.company}
                    required={true}
                    type={'email'}
                    getValues={getValues}
                  />
                </div>
                <div className={'row'}>
                  {jobsContact && (
                    <FileInput
                      label={'Připojit životopis'}
                      placeholder={'Připojit životopis'}
                      register={register}
                      identificator={'fileName'}
                      error={errors.fileName}
                      required={true}
                      setValue={(val, name) => {
                        setValue('file', val);
                        setValue('fileName', name);
                      }}
                      getValues={getValues}
                    />
                  )}
                </div>
                <FormTextArea
                  label={'Vaše zpráva'}
                  hint={'Prostor pro Vaše dotazy a poznámky'}
                  identificator={'note'}
                  rows={10}
                  register={register}
                />
                <div className={'form-box__controls'}>
                  <div className={'gdpr'}>
                    <div className={'content'}>
                      <div className={`checkbox ${vop ? 'checked' : ''}`} onClick={() => setVop(!vop)} />
                      <p>
                        Odesláním formuláře souhlasíte s{' '}
                        <RouteLink to={routes.conditions.url()} target={'_blank'}>
                          Obchodními podmínkami
                        </RouteLink>{' '}
                        a{' '}
                        <RouteLink to={routes.gdpr.url()} target={'_blank'}>
                          Pravidly ochrany osobních údajů.
                        </RouteLink>
                      </p>
                    </div>
                    <div className={'invalid-feedback'}>{vopError}</div>
                  </div>
                  <div className={'submit-button-wrap'}>
                    <button disabled={isLoading} onClick={handleSubmit(submit)}>
                      Odeslat
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SC.Contact>
  );
};

export default ContactForm;

export const SC = {
  Contact: styled.div<{ jobsContact: boolean | null }>(
    ({ theme, jobsContact }) => css`
      background-color: #eeecde;
      padding-top: 80px;

      .alert {
        display: block;
        padding: 2rem;
        margin-bottom: 1rem;
        border-radius: 20px;
        font: normal 15px/22px 'Area Normal Medium';

        &.alert-success {
          background-color: #32a632;
          color: #fff;
        }

        &.alert-danger {
          background-color: #c9451d;
          color: #fff;
        }
      }

      #contact::before {
        display: block;
        content: ' ';
        margin-top: -120px;
        height: 120px;
        visibility: hidden;
        pointer-events: none;
      }

      .block {
        &-headline {
          padding-bottom: 80px;
          @media (max-width: ${sizes.mDevice}) {
            padding-bottom: 50px;
          }
        }
      }

      .contact-form {
        padding-bottom: 120px;

        .information-wrapper {
          padding-top: 2rem;

          @media (max-width: ${sizes.mDevice}) {
            display: none;
          }

          .information-box {
            background-color: #f7f6f0;
            border-radius: 20px;
            text-align: start;
            display: flex;

            .plus {
              display: block;
              height: 40px;
              width: 40px;
              //background: red;
              transition: transform 0.3s ease-in;
              position: relative;
              margin-bottom: 2rem;

              @media (max-width: ${sizes.mDevice}) {
                margin-top: 0;
                margin-bottom: 3rem;
              }

              .line {
                position: absolute;
                display: block;
                width: 1px;
                height: 25px;
                //position: absolute;
                background: #000000 0 0 no-repeat padding-box;
                opacity: 1;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                @media (max-width: ${sizes.mDevice}) {
                  height: 31px;
                }

                &-vertical {
                  transform: rotate(90deg);
                  top: 20%;
                  @media (max-width: ${sizes.mDevice}) {
                    top: 12%;
                  }

                  //transform: translateY(-50%);
                }
              }
            }

            &__text {
              font: normal 950 15px/25px 'Area Extended Extrablack';
              margin-top: 1rem;
              padding-left: 2rem;
            }
          }
        }

        .form {
          background-color: #f7f6f0;
          border-radius: 20px;
          padding: 4rem 4rem;

          .side {
            &.usp {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              @media (max-width: ${sizes.mDevice}) {
                display: none;
              }
            }

            .row {
              display: flex;
              gap: 2rem;
              width: 100%;
              justify-content: space-between;
            }
          }

          .form-box {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            @media (max-width: ${sizes.mDevice}) {
              grid-template-columns: repeat(1, 1fr);
            }

            .textarea {
              label {
                text-align: center;
                font: normal 950 14px 'Area Extended Extrablack';
                color: #000;
                //opacity: 0.5;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .input-group {
              width: 100%;

              label {
                font: normal normal 12px/24px 'Area Normal Medium';
                text-align: center;
                opacity: 0.25;
                visibility: hidden;

                &.show {
                  visibility: visible;
                }
              }

              &.error {
                input {
                  border-bottom: 1px solid #bb3100;

                  &:hover {
                    background-color: #f6e600;
                    color: black;

                    ::-webkit-input-placeholder {
                      color: #000;
                    }

                    :-moz-placeholder {
                      /* Firefox 18- */
                      color: #000;
                    }

                    ::-moz-placeholder {
                      /* Firefox 19+ */
                      color: #000;
                    }

                    :-ms-input-placeholder {
                      color: #000;
                    }
                  }

                  ::-webkit-input-placeholder {
                    color: #bb3100;
                  }

                  :-moz-placeholder {
                    /* Firefox 18- */
                    color: #bb3100;
                  }

                  ::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #bb3100;
                  }

                  :-ms-input-placeholder {
                    color: #bb3100;
                  }
                }
              }
            }

            input {
              text-align: start;
              border: none;
              border-radius: 0;
              background: none;
              border-bottom: 1px solid black;
              transition: background-color 0.2s ease-in-out;
              font: normal 950 14px 'Area Extended Extrablack';
              width: 100%;

              ::-webkit-input-placeholder {
                color: black;
              }

              :-moz-placeholder {
                /* Firefox 18- */
                color: black;
              }

              ::-moz-placeholder {
                /* Firefox 19+ */
                color: black;
              }

              :-ms-input-placeholder {
                color: black;
              }

              &:hover {
                background-color: #f6e600;
                color: black;

                ::-webkit-input-placeholder {
                  color: black;
                }

                :-moz-placeholder {
                  /* Firefox 18- */
                  color: black;
                }

                ::-moz-placeholder {
                  /* Firefox 19+ */
                  color: black;
                }

                :-ms-input-placeholder {
                  color: black;
                }
              }
            }

            textarea {
              border-radius: 0;
              background: none;
              border: 1px solid black;
              transition: background-color 0.2s ease-in-out;
              padding: 2rem 2rem;
              font: normal normal medium 20px/24px 'Area Normal Black';
              width: 100%;
              height: ${!jobsContact ? '76%' : '81%'};

              @media (max-width: ${sizes.mDevice}) {
                margin-top: 2rem;
              }

              &:hover {
                background-color: #f6e600;
                color: black;

                ::-webkit-input-placeholder {
                  color: black;
                }

                :-moz-placeholder {
                  /* Firefox 18- */
                  color: black;
                }

                ::-moz-placeholder {
                  /* Firefox 19+ */
                  color: black;
                }

                :-ms-input-placeholder {
                  color: black;
                }
              }
            }

            &__controls {
              align-items: center;
              margin-top: 2rem;

              @media (max-width: ${sizes.mDevice}) {
                display: block;
              }

              .gdpr {
                font: normal normal 14px/20px 'Area Normal Medium';

                .content {
                  display: flex;
                }

                .checkbox {
                  &:before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    border: 1px solid #000000;
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    border-radius: 5px;
                    cursor: pointer;
                    margin-right: 1rem;
                  }

                  &.checked {
                    &:before {
                      background: url('/icons/checked_green.svg') no-repeat;
                      background-position-x: 4px;
                      background-position-y: 5px;
                    }
                  }
                }

                .invalid-feedback {
                  display: block;
                  color: brown;
                  margin-left: 3rem;
                }

                a {
                  text-decoration: underline;
                  font: normal normal 14px/20px 'Area Normal Black';
                }

                p {
                  margin-top: 2px;
                }
              }

              .submit-button-wrap {
                text-align: end;
                padding-top: 2rem;
                @media (max-width: ${sizes.mDevice}) {
                  margin-top: 1rem;
                  width: 100%;
                }
              }

              button {
                transition: background-color 0.1s ease-in-out;
                border: 1px solid black;
                background: none;
                border-radius: 4px;
                padding: 1.5rem 3rem;
                font: normal 950 15px 'Area Normal Black';

                @media (max-width: ${sizes.mDevice}) {
                  width: 100%;
                }

                &:hover {
                  background-color: #f6e600;
                  border: 1px solid transparent;
                }
              }
            }
          }
        }
      }

      .contact-banner {
        font: normal 950 25px/39px 'Area Normal ExtraBlack';
        border-radius: 20px;
        width: 75%;
        padding-bottom: 80px;

        @media (max-width: ${sizes.mDevice}) {
          padding-bottom: 50px;
        }

        &-bottom {
          font: normal normal 18px/35px 'Area Normal Medium';
          width: 100%;
          padding: 2rem;
          line-height: initial;

          .title {
            font: normal 950 18px/35px 'Area Extended ExtraBlack';
            padding-bottom: 1rem;
          }

          .plus {
            display: block;
            height: 40px;
            width: 40px;
            //background: red;
            transition: transform 0.3s ease-in;
            position: relative;
            margin-bottom: 2rem;

            @media (max-width: ${sizes.mDevice}) {
              margin-top: 0;
              margin-bottom: 3rem;
            }

            .line {
              position: absolute;
              display: block;
              width: 1px;
              height: 25px;
              //position: absolute;
              background: #000000 0 0 no-repeat padding-box;
              opacity: 1;
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              top: 50%;
              transform: translateY(-50%);

              @media (max-width: ${sizes.mDevice}) {
                height: 31px;
              }

              &-vertical {
                transform: rotate(90deg);
                top: 20%;
                @media (max-width: ${sizes.mDevice}) {
                  top: 12%;
                }

                //transform: translateY(-50%);
              }
            }
          }

          .link {
            display: inline-block;
            text-decoration: underline;
            color: #000;
            cursor: pointer;
            position: relative;
            font: normal 950 18px/35px 'Area Normal Black';
          }
        }

        @media (max-width: ${sizes.mDevice}) {
          font-size: 25px;
          line-height: 33px;
          width: 100%;
        }
      }
    `
  ),
};
