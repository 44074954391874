import { useMutation } from 'react-query';
import ApiClient from '../../../lib/api/src/api';
import { FlashMessage, GenericResponse } from '../../common/models';

interface MutationValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  tariff?: string | null;
}

interface Options {
  onSuccess: (val: string) => void;
  onError: (val: string) => void;
}

export const useContactUsMutation = (props: Options) => {
  return useMutation<GenericResponse<null>, Response, MutationValues>(
    async (values: MutationValues) => {
      return await new ApiClient().post('CONTACT_US', {
        data: values,
      });
    },
    {
      onSuccess: async (resp) => {
        if (!resp.success) {
          const mes = resp.flashes
            ?.map((message: FlashMessage) => {
              return message.message;
            })
            .join(', ');
          return;
        }

        props.onSuccess(`${resp.timestamp}`);
      },
      onError: async (resp) => {
        props.onError('error');
      },
    }
  );
};
